<template>
  <div>
     <base-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :showFooter="false"
      title="文件处理履历"
    >
     <!-- 查询条件 -->
      <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
     <!-- 表格数据 -->
    <base-table

      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data:tableData,
        stripe: false,
        border: true,
        height: 400,
        spanMethod: objectSpanMethod,
      }"
      @pageIndexChange="pageIndexChange"
      @pageSizeChange="pageSizeChange"
      :webPage="true"
       :isCaculateHeight="false"
      ref="tableData"
      >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <template slot="action" slot-scope="scope">
        <icon-button  @click="preViewFile(scope.row)" content="预览" icon="iconfont iconyulan" />
        <icon-button  @click="dowloadFile(scope.row.fileId)" content="下载" icon="iconfont iconxiazai"/>
      </template>
    </base-table>
      <template slot="footer">
        <base-button
          label="关 闭"
          type="default"
          @click="dialogVisible = false"
        ></base-button>
      </template>
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="preViewcount" />
    </base-dialog>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import PreView from '@/components/pre-view/pre-view.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { downAPi } from '@/api/fileApi'
import { docHistoryFormConfig, docHistoryTable } from './confing'
import { fileListApi } from '@/api/businessApi'
import { getDictLists } from '@/filters/fromDict'

import { downFile } from '@/utils/devUtils'
import Storage from '@/utils/storage'

export default {
  components: { BaseDialog, BaseButton, BaseForm, BaseTable, PreView, IconButton },
  props: {
    docHistoryVisible: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    docHistoryData: {
      type: Object
    },
    fileTypeLists: {
      type: Array
    }
  },
  data () {
    return {
      fileId: '',
      fileType: '',
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      preViewcount: 0
      // operateTypeOptions: [
      //   { dictId: '10', dictName: '生成' },
      //   { dictId: '20', dictName: '上传' },
      //   { dictId: '30', dictName: '编辑' },
      //   { dictId: '40', dictName: '删除' }
      // ]
    }
  },
  computed: {
    dialogVisible: {
      get () {
        return this.docHistoryVisible
      },
      set (val) {
        this.$emit('update:docHistoryVisible', val)
      }
    },
    operateTypeOptions () {
      return getDictLists('OPERATE_TYPE')
    },
    formConfig () {
      return docHistoryFormConfig(this)
    },
    columns () {
      return docHistoryTable(this)
    }
  },
  watch: {
    dialogVisible (val) {
      if (val && this.docHistoryData) {
        // this.queryParas.pageSize = 999
        console.log(this.docHistoryData, 'this.docHistoryData')
        // this.$set(this.queryParas, 'businessNo', Storage.getLocal('examine').businessNo)
        this.$set(this.queryParas, 'documentId', this.docHistoryData.documentId)
        this.$set(this.queryParas, 'businessNo', Storage.getSession('examine').finBusuinessInfoVO.businessNo)
        this.$nextTick(() => {
          this.handleFilter()
        })
      }
    }
  },
  mounted () {

  },
  methods: {
    pageIndexChange (data) {
      this.queryParas.pageIndex = data
      this.handleFilter()
    },
    pageSizeChange (data) {
      this.queryParas.pageSize = data
      this.handleFilter()
    },
    // 合并数据
    filterData (arr = [], innerKey, orderKey) {
      const res = []
      const map = {}
      arr.forEach((item) => {
        const key = item[innerKey]
        item[orderKey] = 0
        if (!map[key]) {
          map[key] = [item]
        } else {
          map[key].push(item)
        }
      })
      for (const key in map) {
        const list = map[key]
        list[0][orderKey] = list.length
        res.push(...list)
      }
      return res
    },
    // 第二次 >>>>暂时不二次合并
    newFilterData (arr = [], innerKey, orderKey) {
      const res = []
      const map = {}
      arr.forEach((item) => {
        const key = item[innerKey]
        item[orderKey] = 0
        if (!map[key]) {
          map[key] = [item]
        } else {
          map[key].push(item)
        }
      })
      for (const key in map) {
        const list = map[key]
        list[0][orderKey] = list.length
        if (innerKey === 'businessNo') {
          res.push(
            ...this.filterData(
              list,
              'documentId',
              'documentIdNameRowSpan'
            )
          )
        } else {
          res.push(...list)
        }
      }
      return res
    },
    // 获取表格数据
    getListData () {
      fileListApi.contractsRecordsPage(this.queryParas).then(res => {
        const newData = JSON.parse(JSON.stringify(res.data.records))
        this.tableData = this.filterData(newData, 'businessNo', 'businessNoNameRowSpan')
        this.tableData = this.newFilterData(newData, 'businessNo', 'businessNoNameRowSpan')
        this.$refs.tableData.total = res.data.total
      })
    },
    // 合并
    objectSpanMethod ({ row, column }) {
      if (column.property === 'businessNo') {
        const _row = row.businessNoNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'documentName') {
        const _row = row.documentIdNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      // if (column.property === 'fileName') {
      //   const _row = row.fileIdNameRowSpan
      //   const _col = _row > 0 ? 1 : 0
      //   return {
      //     rowspan: _row,
      //     colspan: _col
      //   }
      // }
    },
    // 预览
    preViewFile (data) {
      this.fileId = data.fileId.toString()
      this.fileType = data.fileName ? data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4) : 'pdf'
      this.preViewcount++
    },
    // 下载
    dowloadFile (fileId) {
      // 下载文件
      if (fileId) {
        const params = { keyId: fileId.toString() }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    handleFilter () {
      // this.queryParas.pageSize = 999
      this.getListData()
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    }
  }
}
</script>
<style lang="scss" scoped>

.dowlaodbtn{
  padding: 5px 10px;
  text-align: right;
}
.actionBox{
  width: 120px;
  display: flex;
}
</style>
